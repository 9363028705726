import AnalyticsStore from '@/store/modules/AnalyticsStore';
import {
  AnalysisStep,
  AnalysisSubstep,
  CLUSTERING_ANALYSIS,
  CONSTITUENT_RISK,
  FACTSHEET,
  FOLDER,
  PERFORMANCE_ATTRIBUTION,
  POSITION,
  NO_CODE_DASHBOARD,
} from '@/types/analytics/AnalysisStep';
import { FACTOR_DECOMPOSITION } from '@/types/analytics/FactorDecomposition';
import {
  PORTFOLIO_CONSTRUCTION,
  PORTFOLIO_CONSTRUCTION_EFFICIENT_FRONTIER,
  PORTFOLIO_CONSTRUCTION_PERF_ATTRIB,
} from '@/types/analytics/PortfolioConstruction';
import { computed, ComputedRef, Ref, ref } from 'vue';
import { useFeatureFlag } from './useFeatureFlag';
import useRouteChecks from './useRouteChecks';
import { usePerformanceContributionDates } from './usePerformanceContributionData';
import { Route } from 'vue-router';
import { useIndexUniqueIdentifier, useStrategyIdentifier } from './useCorrectIdentifier';
import { useConstituentPositionDates } from './queries/useConstituentPositionData';
import useEnv from './useEnv';
import usePortfolioTree from './usePortfolioTree';
import useHackyPortfolioMap from './useHackyPortfolioMap';
import { RouteName } from '@/constants/RouteName';
import { RiskTabs } from '@/types/analytics/ConstituentRisk';
import { useRouteRef } from './useRouter';
import { UserModule } from '@/store/modules/user';
import { useLastUsedPortfolioSlug } from './useCachedAnalysisParameters';
import { Location } from 'vue-router';
import { PortfolioConstructionTabs } from '@/types/PortfolioConstructionTabs';
import { isPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';
import { useAllPortfoliosBySlug } from './queries/useDataDiscovery';
import { PortfolioType } from '@/types/portfolio';
import { isConstituentPortfolioFn } from '@/utils/portfolioTree';

const activeAnalysisStep: ComputedRef<AnalysisStep> = computed(() => AnalyticsStore.activeAnalysisStep);

export default function (): {
  activeAnalysisStep: ComputedRef<AnalysisStep>;
} {
  return {
    activeAnalysisStep,
  };
}

const createComposableForAnalysisStep = (step: AnalysisStep) => {
  const availableAnalysisSubsteps = step.substeps;
  const activeAnalysisSubstep = ref<AnalysisSubstep | null>(null);
  const findSubstepByRoute = (route: string): AnalysisSubstep | undefined => {
    return step.substeps.find((s): boolean => s.path === route);
  };

  return (): {
    availableAnalysisSubsteps: AnalysisSubstep[];
    activeAnalysisSubstep: Ref<AnalysisSubstep | null>;
    findSubstepByRoute: (route: string) => AnalysisSubstep | undefined;
  } => {
    return {
      availableAnalysisSubsteps,
      activeAnalysisSubstep,
      findSubstepByRoute,
    };
  };
};

export const usePortfolioConstructionAnalysisSteps = createComposableForAnalysisStep(PORTFOLIO_CONSTRUCTION);
export const useFactorDecompositionAnalysisSteps = createComposableForAnalysisStep(FACTOR_DECOMPOSITION);
export const useConstituentRiskAnalysisSteps = createComposableForAnalysisStep(CONSTITUENT_RISK);

export function useActiveSubstep() {
  const { activeAnalysisSubstep } = usePortfolioConstructionAnalysisSteps();

  const isOnFrontierSubstep = computed((): boolean => {
    return (
      activeAnalysisStep.value === PORTFOLIO_CONSTRUCTION &&
      activeAnalysisSubstep.value != null &&
      activeAnalysisSubstep.value.path === PORTFOLIO_CONSTRUCTION_EFFICIENT_FRONTIER.path
    );
  });

  const isOnPerfAttribSubstep = computed(() => {
    return (
      activeAnalysisStep.value === PORTFOLIO_CONSTRUCTION &&
      activeAnalysisSubstep.value != null &&
      activeAnalysisSubstep.value.path === PORTFOLIO_CONSTRUCTION_PERF_ATTRIB.path
    );
  });

  return {
    isOnFrontierSubstep,
    isOnPerfAttribSubstep,
  };
}

export function useAnalysisStepItems(route: Ref<Route>, isAnalysisToolModal: boolean = false) {
  const indexUniqueIdentifier = useIndexUniqueIdentifier();
  const { isPortfolioPage, isStrategyPage } = useRouteChecks(route);

  const strategyIdentifier = useStrategyIdentifier();

  const isDemoPortfolio = computed(() => {
    if (!indexUniqueIdentifier.value) return false;
    return indexUniqueIdentifier.value.includes('demo_portfolio');
  });

  const perfDates = usePerformanceContributionDates(strategyIdentifier, {
    enabled: computed(() => !!strategyIdentifier.value && !isPortfolioPage.value),
  });

  /**
   * In the future we will have to check portfolio pages as well.
   * For now, we only support strategy pages
   */
  const { data: positionDates } = useConstituentPositionDates(indexUniqueIdentifier, { enabled: isStrategyPage });

  const {
    canSwitchPortfolios,
    shouldConstituentRiskBeVisible,
    shouldPerformanceContributionBeVisible,
    shouldClusteringAnalysisBeVisible,
    shouldFactorDecompositionBeVisible,
    shouldPreventStrategyFactsheetAccess,
    hasPositionPageAccess,
    canSeeNoCodeDashboard,
    hasConstituentRiskSimulationAccess,
    canSeeRestrictedAnalyticsTools,
  } = useFeatureFlag();

  const { isBofAEnvironment } = useEnv();

  const { isMasterIndex } = usePortfolioTree();

  const availableAnalysisStepItems = computed((): AnalysisStep[] => {
    const retval: AnalysisStep[] = [];

    if (isBofAEnvironment) {
      if (canSwitchPortfolios.value && canSeeRestrictedAnalyticsTools.value) {
        retval.push(FOLDER);
      }

      // if it's a portfolio means
      retval.push(PORTFOLIO_CONSTRUCTION);

      if (isPortfolioPage.value || isStrategyPage.value) {
        retval.push(FACTSHEET);
      }

      /**
       * Only if the user has position page access, show the position page
       * If the strategy has positions, show the position page
       * If ths user is on a master index portfolio, show the position page
       */
      if (
        hasPositionPageAccess.value &&
        ((isStrategyPage.value && positionDates.value?.length) || isMasterIndex.value)
      ) {
        retval.push(POSITION);
      }

      if (shouldConstituentRiskBeVisible.value && canSeeRestrictedAnalyticsTools.value) {
        retval.push(CONSTITUENT_RISK);
      }

      if (perfDates.data.value?.length && shouldPerformanceContributionBeVisible.value) {
        retval.push(PERFORMANCE_ATTRIBUTION);
      }

      return retval;
    }

    if (canSwitchPortfolios.value) {
      retval.push(FOLDER);
    }

    if ((isAnalysisToolModal || isPortfolioPage.value) && !isDemoPortfolio.value) {
      retval.push(PORTFOLIO_CONSTRUCTION);
    }

    // Currently shouldClusteringAnalysisBeVisible would only be true to core
    if (
      (isAnalysisToolModal || isPortfolioPage.value) &&
      !isDemoPortfolio.value &&
      shouldClusteringAnalysisBeVisible.value
    ) {
      retval.push(CLUSTERING_ANALYSIS);
    }

    // Currently shouldFactorDecompositionBeVisible would only be true to core
    if (!isDemoPortfolio.value && shouldFactorDecompositionBeVisible.value) {
      retval.push(FACTOR_DECOMPOSITION);
    }

    // TODO: Only keep 'shouldConstituentRiskBeVisible' after the feature is validated
    if (
      (shouldConstituentRiskBeVisible.value && isPortfolioPage.value) ||
      (hasConstituentRiskSimulationAccess.value && !isPortfolioPage.value)
    ) {
      retval.push(CONSTITUENT_RISK);
    }

    if (
      (isPortfolioPage.value && !isDemoPortfolio.value) ||
      (!isPortfolioPage.value && !shouldPreventStrategyFactsheetAccess.value)
    ) {
      retval.push(FACTSHEET);
    }

    if (perfDates.data.value?.length && shouldPerformanceContributionBeVisible.value) {
      retval.push(PERFORMANCE_ATTRIBUTION);
    }

    /**
     * In the future we will have to check portfolio pages as well.
     * For now, we only support strategy pages
     * TODO: Create proper user permission for Position Page to enable/disable from admin console
     */
    if (hasPositionPageAccess.value && !isPortfolioPage.value && positionDates.value?.length) {
      retval.push(POSITION);
    }

    if (canSeeNoCodeDashboard.value) {
      retval.push(NO_CODE_DASHBOARD);
    }

    return retval;
  });

  return {
    availableAnalysisStepItems,
  };
}

export function useAnalysisStepTo() {
  const route = useRouteRef();
  const { isStrategyPage, isPortfolioFactsheet } = useRouteChecks(route);
  const indexUniqueIdentifier = useIndexUniqueIdentifier();
  const { isMasterIndex, masterPortfolioTree, itemUnderAnalysis } = usePortfolioTree();
  const allPortfolioTreesBySlug = useAllPortfoliosBySlug();
  const { portfolio: lastPortfolioSlug } = useLastUsedPortfolioSlug();
  const isConstituent = isConstituentPortfolioFn(masterPortfolioTree);

  const { getSlugBeforeLastHyphen, hackyPortfolioMap } = useHackyPortfolioMap();
  const slugBeforeLastHyphen = computed(() => {
    return getSlugBeforeLastHyphen(indexUniqueIdentifier.value);
  });
  const { hasHackyPortfolioMap, hasConstituentRiskSimulationAccess } = useFeatureFlag();
  const shouldUseHackyPortfolioMap = computed(
    () => hasHackyPortfolioMap.value && slugBeforeLastHyphen.value && slugBeforeLastHyphen.value in hackyPortfolioMap,
  );

  /**
   * If the lastPortfolioSlug is not set, then return the default portfolio slug
   */
  const portfolioSlugToUse = computed(() => {
    /**
     * If the user visit portfolio factsheet through data universe,
     * the selected portfolio would not be saved into last used slug.
     * To persist user selection, we need to use the indexUniqueIdentifier.
     */
    if (isPortfolioFactsheet.value && indexUniqueIdentifier.value) return indexUniqueIdentifier.value;
    return lastPortfolioSlug.value || UserModule.user?.defaultSlug;
  });

  const portfolioRiskSlugToUse = computed(() => {
    /**
     * If the user visit portfolio factsheet through data universe,
     * the selected risk portfolio would not be saved into last used slug.
     * To persist user selection, we need to use the indexUniqueIdentifier.
     * If the selected portfolio is not a risk portfolio, we fallback to the logic below.
     */
    if (isPortfolioFactsheet.value && isConstituent.value && indexUniqueIdentifier.value)
      return indexUniqueIdentifier.value;
    /**
     * If the user has access to the constituent risk simulation,
     * return the indexUniqueIdentifier/ lastPortfolioSlug regardless if it is a risk portfolio
     */
    if (hasConstituentRiskSimulationAccess.value) {
      if (isPortfolioFactsheet.value && indexUniqueIdentifier.value) return indexUniqueIdentifier.value;
      return lastPortfolioSlug.value;
    }

    // If lastPortfolioSlug is not set, then return the default risk portfolio slug
    if (!lastPortfolioSlug.value) return UserModule.user?.defaultRiskSlug;

    // If the user doesn't have access to the constituent risk simulation, then return the lastPortfolioSlug if it is a risk portfolio
    // else return the default risk portfolio (which could be undefined and trigger the analysis tool modal in the onclick event)
    const lastPortfolio = allPortfolioTreesBySlug.data.value?.get(lastPortfolioSlug.value);
    return lastPortfolio?.type === PortfolioType.CONSTITUENT
      ? lastPortfolioSlug.value
      : UserModule.user?.defaultRiskSlug;
  });

  const portfolioConstructionTo = computed((): Location | undefined => {
    if (portfolioSlugToUse.value) {
      return {
        name: RouteName.PORTFOLIO_CONSTRUCTION,
        params: {
          indexUniqueIdentifier: portfolioSlugToUse.value,
          substep: PortfolioConstructionTabs.SIMULATION,
        },
      };
    }

    // If portfolioSlugToUse is undefined, then return undefined and show the analysis tool modal
    return undefined;
  });

  /**
   * Until the portfolio tree is restructured so that a portfolio tree may have both risk data
   * and a custom weighting, we are implementing a hacky map such that if Simplex users
   * are on their risk portfolio and go to Portfolio Construction, they will see their
   * custom weighting portfolio. And vice versa, if they click on Risk.
   *
   * Excepting this unusual case, if we are on a Risk portfolio, then we allow them through.
   * Otherwise we show the Analysis Tool Modal so that they can select a Risk portfolio to analyze on the Risk page.
   */
  const constituentTo = computed((): Location | undefined => {
    if (slugBeforeLastHyphen.value && shouldUseHackyPortfolioMap.value) {
      return {
        name: RouteName.CONSTITUENT_RISK_PORTFOLIO,
        params: {
          indexUniqueIdentifier: hackyPortfolioMap[slugBeforeLastHyphen.value].risk,
          substep: RiskTabs.VAR,
        },
      };
    }

    /**
     * If the user is on a strategy page and has access to the constituent risk simulation,
     * then go to the strategy risk page with the current indexUniqueIdentifier
     */
    if (isStrategyPage.value && hasConstituentRiskSimulationAccess.value) {
      return {
        name: RouteName.CONSTITUENT_RISK_STRATEGY,
        params: {
          indexUniqueIdentifier: indexUniqueIdentifier.value,
          substep: RiskTabs.VAR,
        },
      };
    }

    if (portfolioRiskSlugToUse.value) {
      return {
        name: RouteName.CONSTITUENT_RISK_PORTFOLIO,
        params: {
          indexUniqueIdentifier: portfolioRiskSlugToUse.value,
          substep: RiskTabs.VAR,
        },
      };
    }

    // If none of the condition above matches, then return undefined and show the analysis tool modal
    return undefined;
  });

  /**
   * If we are on a master index portfolio and we are NOT using the hackyPortfolioMap,
   * then we should go to the master index factsheet (strategy factsheet)
   * HackyPortfolioMap is only used for simplex semi whitelabel use case
   *
   * TODO WAA-11239 Darshna verifies for linked portfolios
   */
  const shouldGoToMasterIndexFactsheet = computed(() => isMasterIndex.value && !shouldUseHackyPortfolioMap.value);

  /**
   *  If we are on a master index portfolio, then we use the master index portfolio reference
   */
  const slugToUseForMasterIndex = computed(() => {
    if (isMasterIndex.value) {
      return masterPortfolioTree.value?.portfolioTree.reference ?? indexUniqueIdentifier.value;
    }
    return indexUniqueIdentifier.value;
  });

  /**
   * If the slug prefix matches an item in the hackyPortfolioMap, then this variable will return the
   * NON-RISK portfolio slug
   */
  const slugToUseForFactsheet = computed(() => {
    if (shouldGoToMasterIndexFactsheet.value) {
      return slugToUseForMasterIndex.value;
    }

    return slugBeforeLastHyphen.value && shouldUseHackyPortfolioMap.value
      ? hackyPortfolioMap[slugBeforeLastHyphen.value].nonRisk
      : indexUniqueIdentifier.value;
  });

  const factsheetTo = computed(() => {
    const shouldUseStrategyFactsheet = isStrategyPage.value || shouldGoToMasterIndexFactsheet.value;

    return {
      name: shouldUseStrategyFactsheet ? RouteName.STRATEGY_FACTSHEET : RouteName.PORTFOLIO_FACTSHEET,
      params: {
        indexUniqueIdentifier: slugToUseForFactsheet.value,
      },
    };
  });

  const positionTo = computed(() => {
    return {
      name: RouteName.POSITION,
      params: {
        indexUniqueIdentifier: slugToUseForMasterIndex.value,
      },
    };
  });

  const noCodeDashboardTo = computed(() => {
    return {
      name: RouteName.NO_CODE_DASHBOARD,
    };
  });

  const clusteringAnalysisTo = computed(() => {
    if (portfolioSlugToUse.value) {
      return {
        name: RouteName.CLUSTERING_ANALYSIS,
        params: {
          indexUniqueIdentifier: portfolioSlugToUse.value,
        },
      };
    }

    return undefined;
  });

  const factorDecompositionTo = computed(() => {
    if (isStrategyPage.value) {
      return {
        name: RouteName.FACTOR_DECOMPOSITION_STRATEGY,
        params: {
          indexUniqueIdentifier: indexUniqueIdentifier.value,
        },
      };
    }

    if (lastPortfolioSlug.value) {
      return {
        name: RouteName.FACTOR_DECOMPOSITION_PORTFOLIO,
        params: {
          indexUniqueIdentifier: lastPortfolioSlug.value,
        },
        query:
          itemUnderAnalysis.value && isPortfolioTreeSubportfolio(itemUnderAnalysis.value)
            ? {
                id: itemUnderAnalysis.value.portfolioTreeId,
              }
            : undefined,
      };
    }

    return undefined;
  });

  const performanceAttributionTo = computed(() => {
    return {
      name: RouteName.PERFORMANCE_ATTRIBUTION,
      params: {
        indexUniqueIdentifier: slugToUseForMasterIndex.value,
      },
    };
  });

  return {
    constituentTo,
    portfolioConstructionTo,
    portfolioSlugToUse,
    factsheetTo,
    noCodeDashboardTo,
    clusteringAnalysisTo,
    positionTo,
    factorDecompositionTo,
    performanceAttributionTo,
  };
}
