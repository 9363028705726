import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  deleteCustomStressTest,
  getCustomStressTest,
  updateCustomStressTest,
  uploadCustomStressTest,
} from '@/api-v2/web/risk-dashboard';
import { CustomStressTest } from '@/types/analytics/ConstituentRisk';
@Module({ dynamic: true, store, name: 'ConstituentRisk', namespaced: true })
class ConstituentRisk extends VuexModule {
  public isHistoryMode = false;

  public customStressTests: CustomStressTest[] = [];

  @Mutation
  private TOGGLE_HISTORY_MODE(shouldTurnOnHistory: boolean): void {
    this.isHistoryMode = shouldTurnOnHistory;
  }
  /**
   * Saves the constituent risk preferences as a cookie every time they are changed
   */
  @Action({ rawError: true })
  public async ToggleHistoryMode(shouldTurnOnHistory: boolean): Promise<void> {
    this.TOGGLE_HISTORY_MODE(shouldTurnOnHistory);
  }

  @Action({ rawError: true })
  public async GetCustomStressTests(): Promise<void> {
    const options = await getCustomStressTest();

    this.GET_CUSTOM_STRESS_TESTS({ options });
  }

  @Mutation
  private GET_CUSTOM_STRESS_TESTS({ options }: { options: CustomStressTest[] }): void {
    this.customStressTests = options;
  }

  @Action({ rawError: true })
  public async SetCustomStressTests(body: CustomStressTest): Promise<void> {
    await uploadCustomStressTest(body);
  }

  @Action({ rawError: true })
  public async UpdateCustomStressTests(params: { attributeId: string; body: CustomStressTest }): Promise<void> {
    await updateCustomStressTest(params);
  }

  @Action({ rawError: true })
  public async DeleteCustomStressTests(attributeId: string): Promise<void> {
    await deleteCustomStressTest(attributeId);
  }
}

export const ConstituentRiskModule = getModule(ConstituentRisk);
