import AnalyticsStore from '@/store/modules/AnalyticsStore';
import { IPortfolioTree, isPortfolioTreeStrategy, isPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';
import { IItemUnderAnalysis } from '@/types/IItemUnderAnalysis';
import { findTreeComponentByStrategyCode } from '@/utils/portfolioTree';
import { computed, ComputedRef, Ref } from 'vue';
import { isStrategy } from '@/utils/strategy';
import { ReturnTypeConstants } from '@/constants/ReturnTypeConstants';
import { BasketStatusConstants } from '@/constants/BasketStatusConstants';
import { DateTime } from 'luxon';
import { PortfolioModule } from '@/store/modules/PortfolioStore';
import { usePortfolioVersionPreferences } from '@/composables/usePortfolioVersionPreferences';

/**
 * @deprecated - should be replaced with `usePortfolioTreeDraft`
 */
const masterPortfolioTree = computed((): IPortfolioTree | null => {
  return PortfolioModule.portfolioTreeUnderAnalysis;
});

const itemUnderAnalysis = computed(() => AnalyticsStore.itemUnderAnalysis);

const portfolioName = computed((): string | null => {
  if (PortfolioModule.portfolioTreeUnderAnalysis) {
    return PortfolioModule.portfolioTreeUnderAnalysis.portfolioTree.name;
  }
  return null;
});

const hasActiveCodes = computed((): boolean => PortfolioModule.hasActiveCodes);

/**
 * When a portfolio is marked as read-only, we must enable the read-only mode for the user.
 */
const isReadOnlyPortfolio = computed(() => masterPortfolioTree.value?.parameters?.isReadOnlyPortfolio ?? false);

/**
 * When a portfolio is marked as rebalance eligible, we must enable the rebalance button for the user.
 */
const isRebalancingEligible = computed(() => masterPortfolioTree.value?.parameters?.isRebalanceEligible ?? false);

/**
 * Flag to determine if the current portfolio is linked to a master index
 */
const isMasterIndex = computed(() => !!masterPortfolioTree.value?.portfolioTree.isMasterIndex);

/**
 * First try the current subportfolio for the given symbol.
 * if it's not found in the current subportfolio tree, then it goes to the current master portfolio tree
 * and searches that
 */
const findPortfolioTreeIdByCode = (symbol: string | undefined) => {
  if (!symbol || !masterPortfolioTree.value) return;

  const portfolioTreeToUse =
    itemUnderAnalysis.value && isPortfolioTreeSubportfolio(itemUnderAnalysis.value)
      ? itemUnderAnalysis.value
      : masterPortfolioTree.value.portfolioTree;

  if (symbol !== portfolioName.value) {
    let component = findTreeComponentByStrategyCode(portfolioTreeToUse, symbol);
    if (!component && itemUnderAnalysis.value && isPortfolioTreeSubportfolio(itemUnderAnalysis.value)) {
      // if component was not found in the subporfolio under analysis, try the whole tree
      component = findTreeComponentByStrategyCode(masterPortfolioTree.value.portfolioTree, symbol);
    }
    if (!component) return;
    return component.portfolioTreeId;
  }
  return masterPortfolioTree.value.portfolioId;
};

/**
 * Check if the current portfolio is young (created in past 60 seconds).
 * This depends on the current time. So we put this in a function instead of computed.
 */
const isPortfolioYoung = (draft?: IPortfolioTree) => {
  if (!draft) return false;

  const age = DateTime.fromISO(draft.createdAt).diffNow('seconds').seconds;

  return Math.abs(age) < 60;
};

/**
 * A portfolio will only have portfolio return hence the returnType will be null.
 * Only tracks (strategies) will have a returnType
 * The condition (isPortfolioTreeStrategy(item) && !item.strategy) needs to be skipped as it can't be clicked
 */
const returnType = computed(() => {
  const item = itemUnderAnalysis.value;
  if (!item) return null;
  if (item) {
    if (isStrategy(item)) {
      return item.returnType ?? ReturnTypeConstants.TOTAL_RETURN;
    }

    if (isPortfolioTreeStrategy(item) && item.strategy) {
      return item.strategy.returnType ?? ReturnTypeConstants.TOTAL_RETURN;
    }
  }
  return null;
});

export default function (): {
  masterPortfolioTree: ComputedRef<IPortfolioTree | null>;
  itemUnderAnalysis: ComputedRef<IItemUnderAnalysis | null>;
  portfolioName: ComputedRef<string | null>;
  findPortfolioTreeIdByCode(symbol: string | undefined): string | undefined;
  hasActiveCodes: ComputedRef<boolean>;
  returnType: ComputedRef<ReturnTypeConstants | null>;
  isReadOnlyPortfolio: ComputedRef<boolean>;
  isRebalancingEligible: ComputedRef<boolean>;
  isPortfolioYoung(draft?: IPortfolioTree): boolean;
  isMasterIndex: ComputedRef<boolean>;
} {
  return {
    masterPortfolioTree,
    itemUnderAnalysis,
    portfolioName,
    findPortfolioTreeIdByCode,
    hasActiveCodes,
    returnType,
    isReadOnlyPortfolio,
    isRebalancingEligible,
    isPortfolioYoung,
    isMasterIndex,
  };
}

/**
 * Function to get various status of the basket
 * These variables will control the visibility of the buttons and other UI elements on the Basket Page
 * @param basket - The basket under analysis
 * @returns - Various status of the basket
 */
export function getBasketStatus(basket: Ref<IPortfolioTree | null>) {
  const { isLiveVersion } = usePortfolioVersionPreferences();

  const basketStatus = computed(() => basket.value?.portfolioTree?.basketStatus);

  const isDraftBasket = computed(() => basketStatus.value === BasketStatusConstants.DRAFT);
  const isBasketPending = computed(() => basketStatus.value === BasketStatusConstants.PENDING);
  const isBasketAcknowledged = computed(() => basketStatus.value === BasketStatusConstants.ACKNOWLEDGED);
  const isBasketApproved = computed(() => basketStatus.value === BasketStatusConstants.APPROVED);
  const isBasketRejected = computed(() => basketStatus.value === BasketStatusConstants.REJECTED);

  const basketSnapshots = computed(() => PortfolioModule.availableSnapshots);

  const latestSnapshot = computed(() =>
    basketSnapshots.value.length ? basketSnapshots.value[basketSnapshots.value.length - 1] : null,
  );

  /**
   * If the basket is in any of the following states, it is undergoing a workflow
   * Pending, Acknowledged, Approved, Rejected
   * Ticker/reference input should be disabled when basket is a master index or is undergoing a rebalancing
   */
  const isBasketUndergoingWorkflow = computed(() =>
    latestSnapshot.value?.snapshot.basketStatus
      ? [BasketStatusConstants.PENDING, BasketStatusConstants.ACKNOWLEDGED, BasketStatusConstants.APPROVED].includes(
          latestSnapshot.value.snapshot.basketStatus,
        )
      : false,
  );

  const hasFirstBasketBeenRejected = computed(
    () =>
      latestSnapshot.value?.snapshot.basketStatus === BasketStatusConstants.REJECTED &&
      (latestSnapshot.value?.snapshot.basketVersion ?? 1) === 1,
  );

  const isProvisionalBasketRejected = computed(
    () => latestSnapshot.value?.snapshot.basketStatus === BasketStatusConstants.REJECTED,
  );

  /**
   * Basket is pending approval if it's in pending or acknowledged state
   * When the basket is in this state, the user cannot make any changes to the basket
   * The UI will be in a read-only state
   */
  const isBasketPendingApproval = computed(() => {
    return isBasketPending.value || isBasketAcknowledged.value;
  });

  const shouldShowNavPanelTenCols = computed(() => {
    return isBasketApproved.value || isLiveVersion.value || isBasketRejected.value;
  });

  /**
   * Editing is disabled if:
   * - the basket is pending approval, or
   * - the basket is live
   */
  const shouldDisableEditingOnBasket = computed(() => {
    return isBasketPendingApproval.value || isLiveVersion.value;
  });

  return {
    isDraftBasket,
    isBasketPending,
    isBasketAcknowledged,
    isBasketApproved,
    isBasketRejected,
    isBasketPendingApproval,
    shouldShowNavPanelTenCols,
    isBasketUndergoingWorkflow,
    hasFirstBasketBeenRejected,
    isProvisionalBasketRejected,
    shouldDisableEditingOnBasket,
  };
}
